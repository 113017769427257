import { ref } from 'vue'
import loadingHandler from '@/use/loadingHandler'
import apiHandler from '@/use/apiHandler'

export default () => {
  const showModalAddNodes = ref(false)
  const availableNodes = ref([])
  const loadHandler = loadingHandler()
  const useApi = apiHandler()
  const selectedAddNodes = ref([])

  const configuration = ref(null)
  const elements = ref([])
  const edges = ref([])

  function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)
    return new Promise((resolve, reject) => {
      useApi
        .request_api('get', 'v1', 'configurations/' + payload.object_id)
        .then((response) => {
          loadHandler.setLoadingState('get_details', false)
          resolve(response.data) // Resolve the promise with the response data
        })
        .catch((error) => {
          loadHandler.setLoadingState('get_details', false)
          reject(error) // Reject the promise if there is an error
        })
    })
  }

  function updateConfiguration(payload, objectId) {
    loadHandler.setLoadingState('update_configuration', true)

    useApi.request_api('patch', 'v1', 'configurations/' + objectId, payload).then(() => {
      loadHandler.setLoadingState('update_configuration', false)
    })
  }

  function openModalAddNodes() {
    showModalAddNodes.value = true
  }

  function closeModalAddNodes() {
    showModalAddNodes.value = false
  }

  function setSelectedAddNodes(payload) {
    selectedAddNodes.value = payload.value
  }

  function listNodes() {
    loadHandler.setLoadingState('list_nodes', true)
    useApi
      .request_api('get', 'v1', 'nodes/?fields=description,place,medium,type,provider_identifier,object_id')
      .then((response) => {
        const data = response.data
        availableNodes.value = data
        loadHandler.setLoadingState('list_nodes', false)
      })
  }

  const nodeFields = ref([
    {
      label: 'Description',
      key: 'description',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Place',
      key: 'place',
      type: 'string',
      searchable: true,
      demodata: 'Demoplaats',
    },
    {
      label: 'Medium',
      key: 'medium',
      type: 'string',
      component: 'pillMainType',
      searchable: true,
      translate_value: true,
    },
    {
      label: 'Identification',
      key: 'provider_identifier',
      type: 'string',
      add: false,
      edit: false,
      searchable: true,
    },
  ])

  return {
    showModalAddNodes,
    openModalAddNodes,
    closeModalAddNodes,
    loadHandler,
    listNodes,
    availableNodes,
    nodeFields,
    setSelectedAddNodes,
    elements,
    edges,
    configuration,
    getDetails,
    updateConfiguration,
    selectedAddNodes,
  }
}
