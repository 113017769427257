<template>
  <div class="w-full h-full">
    <div class="w-full h-full bg-white border border-gray-100 shadow-lg shadow-gray-200">
      <VueFlow
        ref="vueFlowRef"
        class="basicflow h-full w-full"
        :default-viewport="{ zoom: 1.5 }"
        :min-zoom="0.2"
        :max-zoom="4"
        :nodes="useFlow.elements.value"
        :edges="useFlow.edges.value"
        @connect="onConnect"
        @edge-click="onEdgeClick"
        @nodeDragStop="handleDragStop"
        @pane-ready="onPaneReady"
        @node-click="onNodeClick"
        @edge-removed="onEdgeRemoved"
      >
        <Background pattern-color="#aaa" :gap="16" />

        <template #node-electricity="nodeProps">
          <electricityNode
            :id="nodeProps.id"
            :title="nodeProps.data.description"
            :subtitle="nodeProps.data.subtitle"
            :selected="isSelected(nodeProps.id)"
          />
        </template>

        <template #node-gas="nodeProps">
          <gasNode
            id="nodeProps.id"
            :title="nodeProps.data.description"
            :subtitle="nodeProps.data.subtitle"
            :selected="isSelected(nodeProps.id)"
          />
        </template>

        <template #node-water="nodeProps">
          <waterNode
            id="nodeProps.id"
            :title="nodeProps.data.description"
            :subtitle="nodeProps.data.subtitle"
            :selected="isSelected(nodeProps.id)"
          />
        </template>

        <template #node-heat="nodeProps">
          <heatNode
            id="nodeProps.id"
            :title="nodeProps.data.description"
            :subtitle="nodeProps.data.subtitle"
            :selected="isSelected(nodeProps.id)"
          />
        </template>

        <template #node-heatcost="nodeProps">
          <heatCostNode
            id="nodeProps.id"
            :title="nodeProps.data.description"
            :subtitle="nodeProps.data.subtitle"
            :selected="isSelected(nodeProps.id)"
          />
        </template>

        <template #node-summary="nodeProps">
          <summaryNode
            id="nodeProps.id"
            :title="nodeProps.data.description"
            :subtitle="nodeProps.data.subtitle"
            :selected="isSelected(nodeProps.id)"
          />
        </template>

        <template #node-report="nodeProps">
          <reportNode
            id="nodeProps.id"
            :title="nodeProps.data.description"
            :subtitle="nodeProps.data.subtitle"
            :selected="isSelected(nodeProps.id)"
          />
        </template>

        <template #node-time="nodeProps">
          <timeNode
            id="nodeProps.id"
            :title="nodeProps.data.description"
            :subtitle="nodeProps.data.subtitle"
            :selected="isSelected(nodeProps.id)"
          />
        </template>
      </VueFlow>
      <!-- {{ useFlow }} -->
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { VueFlow } from '@vue-flow/core'
import { Background } from '@vue-flow/background'
import electricityNode from '@/views/configurations/nodes/electricityNode.vue'
import gasNode from '@/views/configurations/nodes/gasNode.vue'
import waterNode from '@/views/configurations/nodes/waterNode.vue'
import heatNode from '@/views/configurations/nodes/heatNode.vue'
import heatCostNode from '@/views/configurations/nodes/heatCostNode.vue'
import summaryNode from '@/views/configurations/nodes/summaryNode.vue'
import reportNode from '@/views/configurations/nodes/reportNode.vue'
import timeNode from '@/views/configurations/nodes/timeNode.vue'
import flowHandler from '@/use/flowHandler'

export default {
  emits: ['ready'],
  setup(_, { emit }) {
    const useFlow = flowHandler()

    const selectedEdge = ref(null)
    const selectedNode = ref(null)

    const vueFlowRef = ref(null)

    const onEdgeClick = (event) => {
      selectedEdge.value = event.edge
    }

    const onNodeClick = (event) => {
      selectedNode.value = event.node
      selectedEdge.value = null // Deselect edge if a node is clicked
    }

    const onConnect = (params) => {
      useFlow.addEdges([params])
    }

    const handleKeyPress = (event) => {
      if (event.key === 'Delete') {
        if (selectedEdge.value) {
          useFlow.removeEdges([selectedEdge.value])

          const id = selectedEdge.value.id
          useFlow.edges.value = useFlow.edges.value.filter((edge) => edge.id !== id)

          selectedEdge.value = null
        } else if (selectedNode.value) {
          useFlow.removeElements([selectedNode.value])
          selectedNode.value = null
        }
      }
    }

    function handleDragStop(event) {
      const node = useFlow.elements.value.find((element) => element.id === event.node.id)
      node.position = event.node.position
      node.dimensions = event.node.dimensions
    }

    function onPaneReady(instance) {
      emit('ready', instance)
    }

    function isSelected(id) {
      return selectedNode.value && selectedNode.value.id === id
    }

    const handleDocumentClick = (event) => {
      if (!event.target.closest('.vue-flow__node')) {
        selectedNode.value = null
      }
    }

    const onEdgeRemoved = (event) => {
      const removedEdge = event.detail.edge
      useFlow.edges.value = useFlow.edges.value.filter((edge) => edge.id !== removedEdge.id)
    }

    onMounted(() => {
      document.addEventListener('keydown', handleKeyPress)
      document.addEventListener('click', handleDocumentClick)
    })

    onBeforeUnmount(() => {
      document.removeEventListener('keydown', handleKeyPress)
      document.removeEventListener('click', handleDocumentClick)
    })

    return {
      onConnect,
      onEdgeClick,
      selectedEdge,
      useFlow,
      handleDragStop,
      vueFlowRef,
      onPaneReady,
      onNodeClick,
      isSelected,
      onEdgeRemoved,
    }
  },
  components: {
    VueFlow,
    Background,
    electricityNode,
    gasNode,
    waterNode,
    heatNode,
    heatCostNode,
    summaryNode,
    reportNode,
    timeNode,
  },
}
</script>

<style>
/* import the necessary styles for Vue Flow to work */
@import '@vue-flow/core/dist/style.css';

/* import the default theme, this is optional but generally recommended */
@import '@vue-flow/core/dist/theme-default.css';
</style>
