import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'

export default () => {
  let records = ref([])
  let showAdd = ref(false)
  let showEdit = ref(false)

  const useApi = apiHandler()
  const addForm = formHandler()
  const loadHandler = loadingHandler()

  const fields = ref([
    {
      label: 'Description',
      key: 'description',
      type: 'string',
      searchable: true,
    },
  ])

  function listRecords() {
    loadHandler.setLoadingState('list_configurations', true)

    useApi.request_api('get', 'v1', 'configurations/?fields=description,configuration,object_id').then((response) => {
      const data = response.data
      records.value = data
      loadHandler.setLoadingState('list_configurations', false)
    })
  }

  function openAdd() {
    addForm.create({
      type: 'add',
      fields: fields.value,
      data: {},
    })
    showAdd.value = true
  }

  function add() {
    loadHandler.setLoadingState('add_configuration', true)

    const payload = addForm.data.value

    useApi.request_api('post', 'v1', 'configurations/', payload).then(() => {
      closeAdd()
      listRecords()
      loadHandler.setLoadingState('add_configuration', false)
    })
  }

  function closeAdd() {
    showAdd.value = false
    addForm.close()
  }

  function deleteConfiguration(objectId) {
    const payload = {
      object_id: objectId,
    }
    loadHandler.setLoadingState('delete_scenario', true)
    useApi.request('post', 'scenarios', 'delete', payload).then(() => {
      listRecords()
      loadHandler.setLoadingState('delete_scenario', false)
    })
  }

  const tagsFields = ref([
    {
      label: 'Description',
      key: 'description',
      type: 'string',
      searchable: true,
    },
  ])

  return {
    loadHandler,
    tagsFields,
    records,
    showEdit,
    showAdd,
    listRecords,
    openAdd,
    fields,
    addForm,
    add,
    deleteConfiguration,
    closeAdd,
  }
}
