import { ref, nextTick } from 'vue'
import { useVueFlow } from '@vue-flow/core'
import { v4 as uuidv4 } from 'uuid'
import loadingHandler from '@/use/loadingHandler'
import nodeFuctions from './nodeFunctions'

const elements = ref([])
const edges = ref([])

export default () => {
  const loadHandler = loadingHandler()
  const { removeEdges, fitView } = useVueFlow({ elements })
  const useNodeFunctions = nodeFuctions()

  // Node class to structure each node
  class Node {
    constructor(id, type, dependencies = [], executeFn) {
      this.id = id
      this.type = type
      this.dependencies = dependencies
      this.execute = executeFn
    }
  }

  function loadElements(payload) {
    return new Promise((resolve, reject) => {
      loadHandler.setLoadingState('load_elements', true)

      try {
        if (payload) {
          elements.value = payload.elements
          edges.value = payload.edges
        }
        loadHandler.setLoadingState('load_elements', false)
        resolve()
      } catch (error) {
        loadHandler.setLoadingState('load_elements', false)
        reject(error)
      }
    })
  }

  function customFitView(instance) {
    let minX = Infinity,
      minY = Infinity,
      maxX = -Infinity,
      maxY = -Infinity

    if (instance.elements.value) {
      instance.elements.value.forEach((element) => {
        const nodeWidth = element.dimensions?.width || 50
        const nodeHeight = element.dimensions?.height || 50
        if (element.position) {
          minX = Math.min(minX, element.position.x + nodeWidth / 2)
          minY = Math.min(minY, element.position.y + nodeHeight / 2)
          maxX = Math.max(maxX, element.position.x + nodeWidth / 2)
          // eslint-disable-next-line prettier/prettier
          maxY = Math.max(maxY, element.position.y + nodeHeight)
        }

        // if (element.position) {
        //   minX = Math.min(minX, element.position.x)
        //   minY = Math.min(minY, element.position.y)
        //   maxX = Math.max(maxX, element.position.x)
        //   maxY = Math.max(maxY, element.position.y)
        // }
      })

      const width = maxX - minX
      const height = maxY - minY

      if (instance) {
        instance.fitBounds(
          {
            x: minX,
            y: minY,
            width: width,
            height: height,
          },
          { padding: 1 },
        )
      }
    }
  }

  async function fit() {
    await nextTick()
    setTimeout(() => fitView({ padding: 0.3, includeHiddenNodes: false }), 0)
  }

  function addEdges(payload) {
    payload.forEach((edge) => {
      edge.id = uuidv4()
      edges.value.push(edge)
    })
  }

  function addNodes(nodes) {
    return new Promise((resolve, reject) => {
      loadHandler.setLoadingState('add_nodes', true)

      const offsetX = 20
      const offsetY = 10

      // Calculate the current bounding box of existing nodes
      let maxX = 0
      let maxY = 0

      if (elements.value.length === 0) {
        elements.value.forEach((node) => {
          maxX = Math.max(maxX, node.position.x)
          maxY = Math.max(maxY, node.position.y)
        })
      }

      const boundingOffset = 100

      // Base positions for new nodes start right outside the bounding box
      const baseX = maxX + boundingOffset // Start slightly to the right of the farthest existing node
      const baseY = maxY + boundingOffset // Start just below the lowest existing node

      // This logic assumes no nodes exist, uses a fallback position
      const startX = elements.value.length === 0 ? 100 : baseX
      const startY = elements.value.length === 0 ? 100 : baseY

      try {
        Object.keys(nodes).forEach((node_id, index) => {
          const node = nodes[node_id]

          // Calculate the new position for each node
          const xPos = startX + index * offsetX
          const yPos = startY + index * offsetY

          elements.value.push({
            id: node.object_id,
            data: {
              description: node.description,
              subtitle: node.provider_identifier,
            },
            label: node.provider_identifier,
            type: node.medium.toLowerCase(),
            position: {
              x: xPos,
              y: yPos,
            },
          })
        })

        loadHandler.setLoadingState('add_nodes', false)
        resolve() // Resolve the promise when the operation is complete
      } catch (error) {
        loadHandler.setLoadingState('add_nodes', false)
        reject(error) // Reject the promise in case of an error
      }
    })
  }

  // Add a single node
  function addNode(payload, executeFn) {
    return new Promise((resolve, reject) => {
      // const offsetX = 20
      // const offsetY = 10

      let maxX = 0
      let maxY = 0

      if (elements.value.length !== 0) {
        elements.value.forEach((existingNode) => {
          maxX = Math.max(maxX, existingNode.position.x)
          maxY = Math.max(maxY, existingNode.position.y)
        })
      }

      const boundingOffset = 100
      const baseX = maxX + boundingOffset
      const baseY = maxY + boundingOffset
      const xPos = elements.value.length === 0 ? 100 : baseX
      const yPos = elements.value.length === 0 ? 100 : baseY

      try {
        const nodeId = uuidv4()
        const newNode = new Node(nodeId, payload.type.toLowerCase(), [], executeFn)

        elements.value.push({
          id: newNode.id,
          data: {
            description: `${payload.description}`,
            subtitle: `$subtitle`,
          },
          label: `${payload.type} node`,
          type: newNode.type,
          position: {
            x: xPos,
            y: yPos,
          },
          dependencies: newNode.dependencies,
        })

        resolve(newNode)
      } catch (error) {
        reject(error)
      }
    })
  }

  function removeElements(payload) {
    elements.value = elements.value.filter((element) => !payload.some((item) => item.id === element.id))
  }

  // Function to execute the graph
  async function executeGraph() {
    // Create a map of node ID to node object
    const nodeMap = {}
    elements.value.forEach((element) => {
      nodeMap[element.id] = element
      // Initialize dependencies array
      element.dependencies = []
    })

    // Setup dependencies based on edges
    edges.value.forEach((edge) => {
      if (nodeMap[edge.target] && nodeMap[edge.source]) {
        // nodeMap[edge.target].dependencies.push(edge.source)
        nodeMap[edge.source].dependencies.push(edge.target)
      }
    })

    // Log the node dependencies to verify setup
    Object.values(nodeMap).forEach((node) => {
      console.log(`Node ${node.id} dependencies:`, node.dependencies)
    })

    // Perform a topological sort to find the order of execution
    const sortedNodes = topologicalSort(elements.value, nodeMap)

    console.log(
      'Execution order:',
      sortedNodes.map((node) => node.id),
    )

    // Execute each node in order
    for (const node of sortedNodes) {
      const exc = useNodeFunctions.nodeFunctions[node.type]
      if (exc) {
        // Collect input data from dependency nodes
        const inputData = node.dependencies.map((depId) => {
          const depNode = nodeMap[depId]
          console.log(`Node ${node.id} receives input from node ${depId} with result:`, depNode.data.result)
          return depNode.data.result
        })

        console.log(`Executing node ${node.id} of type ${node.type} with input data:`, inputData)

        const result = await exc(node, elements, edges, inputData)
        node.data.result = result

        // Write the result back to elements
        const index = elements.value.findIndex((el) => el.id === node.id)
        if (index !== -1) {
          elements.value[index] = { ...elements.value[index], data: { ...elements.value[index].data, result } }
        }

        console.log(`Node ${node.id} result:`, result)
      }
    }
  }

  // Helper function to perform topological sort
  function topologicalSort(nodes, nodeMap) {
    const sorted = []
    const visited = new Set()
    const tempMark = new Set()

    function visit(node) {
      if (tempMark.has(node.id)) {
        throw new Error('Graph has cycles')
      }

      if (!visited.has(node.id)) {
        tempMark.add(node.id)

        if (node.dependencies && node.dependencies.length > 0) {
          node.dependencies.forEach((depId) => {
            visit(nodeMap[depId])
          })
        }

        tempMark.delete(node.id)
        visited.add(node.id)
        sorted.push(node)
      }
    }

    nodes.forEach((node) => {
      if (!visited.has(node.id)) {
        visit(node)
      }
    })

    return sorted
  }

  // Example execution functions for nodes
  function exampleNodeFunction() {
    return new Promise((resolve) => {
      setTimeout(() => {
        console.log('Node executed')
        resolve()
      }, 1000)
    })
  }

  return {
    elements,
    edges,
    loadElements,
    addNodes,
    loadHandler,
    addEdges,
    removeEdges,
    fit,
    customFitView,
    removeElements,
    addNode,
    executeGraph,
    exampleNodeFunction,
  }
}
