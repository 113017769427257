import { createRouter, createWebHistory } from 'vue-router'

// overview pages
import viewHome from '@/views/viewHome.vue'
import viewNodes from '@/views/viewNodes.vue'
import viewScenarios from '@/views/viewScenarios.vue'
import viewScenariosAmounts from '@/views/viewScenariosAmounts.vue'
import viewCosts from '@/views/costs/viewCosts.vue'
import viewAmounts from '@/views/amounts/viewAmounts.vue'
import viewClusters from '@/views/viewClusters.vue'
import viewTags from '@/views/viewTags.vue'
import viewContracts from '@/views/viewContracts.vue'
import viewConfigurations from '@/views/configurations/viewConfigurations.vue'

// detail pages
import viewDetailsNode from '@/views/viewDetailsNode.vue'
import viewDetailscluster from '@/views/viewDetailscluster.vue'
import viewDetailsTag from '@/views/viewDetailsTag.vue'
import viewDetailsContract from '@/views/viewDetailsContract.vue'
import viewDetailsConfiguration from '@/views/configurations/viewDetailsConfiguration.vue'

import { authGuard } from '@auth0/auth0-vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: viewHome,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Home',
      titleIcon: 'HomeIcon',
    },
  },
  {
    path: '/nodes',
    name: 'nodes',
    component: viewNodes,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Nodes',
      titleIcon: 'CircleIcon',
    },
  },
  {
    path: '/nodes/:objectId',
    name: 'node_details',
    component: viewDetailsNode,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Node',
      titleIcon: 'CircleIcon',
    },
  },
  {
    path: '/configurations',
    name: 'configurations',
    component: viewConfigurations,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Configurations',
      titleIcon: 'SlidersIcon',
    },
  },
  {
    path: '/configurations/:objectId',
    name: 'configuration_details',
    component: viewDetailsConfiguration,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Configuration',
      titleIcon: 'SlidersIcon',
    },
  },
  {
    path: '/costs',
    name: 'costs',
    component: viewScenarios,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Scenarios',
      titleIcon: 'DollarSignIcon',
    },
  },
  {
    path: '/costs_details/:objectId',
    name: 'costs_details',
    component: viewCosts,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Scenario',
      titleIcon: 'DollarSignIcon',
    },
  },
  {
    path: '/amounts',
    name: 'amounts',
    component: viewScenariosAmounts,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Amounts',
      titleIcon: 'BarChart2Icon',
    },
  },
  {
    path: '/amounts_details/:objectId',
    name: 'amounts_details',
    component: viewAmounts,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Scenario',
      titleIcon: 'BarChart2Icon',
    },
  },
  {
    path: '/clusters',
    name: 'clusters',
    component: viewClusters,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Clusters',
      titleIcon: 'Share2Icon',
    },
  },
  {
    path: '/clusters/:objectId',
    name: 'cluster_details',
    component: viewDetailscluster,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Cluster',
      titleIcon: 'Share2Icon',
    },
  },
  {
    path: '/tags',
    name: 'tags',
    component: viewTags,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Tags',
      titleIcon: 'TagIcon',
    },
  },
  {
    path: '/tags/:objectId',
    name: 'tag_details',
    component: viewDetailsTag,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Tags',
      titleIcon: 'TagIcon',
    },
  },
  {
    path: '/contracts',
    name: 'contracts',
    component: viewContracts,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Contracts',
      titleIcon: 'FileTextIcon',
    },
  },
  {
    path: '/contracts/:objectId',
    name: 'contract_details',
    component: viewDetailsContract,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal_sidebar',
      title: 'Contract',
      titleIcon: 'FileTextIcon',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
