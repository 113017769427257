<template>
  <!-- levering -->
  <base-collapse class="mb-2" v-if="useviewHandlerAmounts.dataElectricity.value.Usage">
    <!-- header -->
    <template v-slot:header>
      <div class="flex flex-row">
        <!-- <span><featherIcon class="w-5 h-5 mr-1" icon="BarChart2Icon" /></span> -->
        <span class="mr-2">{{ $translate('Supply') }}</span>
      </div>
    </template>
    <template v-slot:header-right>
      <div class="mr-2">
        {{ useFilter.filter(sumSummary(useviewHandlerAmounts.dataElectricity.value.Usage), 'kWh') }}
      </div>
    </template>

    <!-- body -->
    <template v-slot:body>
      <cardChart
        :options="useviewHandlerAmounts.chart_options_usage_electricity.value"
        :scatter="useviewHandlerAmounts.filterForm.data.value.interval === 'day'"
        :scatterOptions="useviewHandlerAmounts.chart_options_scatter_electricity.value"
        exportUnit="kWh"
      />
    </template>
  </base-collapse>

  <!-- opwekking -->
  <base-collapse class="mb-2" v-if="useviewHandlerAmounts.dataElectricity.value.Generation">
    <!-- header -->
    <template v-slot:header>
      <div class="flex flex-row">
        <!-- <span><featherIcon class="w-5 h-5 mr-1" icon="BarChart2Icon" /></span> -->
        <span class="mr-2">{{ $translate('Generation') }}</span>
      </div>
    </template>
    <template v-slot:header-right>
      <div class="mr-2">
        {{ useFilter.filter(sumSummary(useviewHandlerAmounts.dataElectricity.value.Generation), 'kWh') }}
      </div>
    </template>

    <!-- body -->
    <template v-slot:body>
      <cardChart
        :options="useviewHandlerAmounts.chart_options_generation_electricity.value"
        :scatter="useviewHandlerAmounts.filterForm.data.value.interval === 'day'"
        :scatterOptions="useviewHandlerAmounts.chart_options_scatter_electricity.value"
        exportUnit="kWh"
      />
    </template>
  </base-collapse>

  <!-- teruglevering -->
  <base-collapse class="mb-2" v-if="useviewHandlerAmounts.dataElectricity.value.Return">
    <!-- header -->
    <template v-slot:header>
      <div class="flex flex-row">
        <!-- <span><featherIcon class="w-5 h-5 mr-1" icon="BarChart2Icon" /></span> -->
        <span class="mr-2">{{ $translate('Return') }}</span>
      </div>
    </template>
    <template v-slot:header-right>
      <div class="mr-2">
        {{ useFilter.filter(sumSummary(useviewHandlerAmounts.dataElectricity.value.Return), 'kWh') }}
      </div>
    </template>

    <!-- body -->
    <template v-slot:body>
      <cardChart
        :options="useviewHandlerAmounts.chart_options_return_electricity.value"
        :scatter="useviewHandlerAmounts.filterForm.data.value.interval === 'day'"
        :scatterOptions="useviewHandlerAmounts.chart_options_scatter_electricity.value"
        exportUnit="kWh"
      />
    </template>
  </base-collapse>
</template>

<script>
import filterHandler from '@/use/filterHandler'
import cardChart from '@/views/components/cardChart.vue'
import viewHandlerAmounts from '@/use/viewHandlerAmounts'

export default {
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  setup() {
    const useFilter = filterHandler()
    const useviewHandlerAmounts = viewHandlerAmounts()

    function sumSummary(data) {
      if (data) {
        let sum = 0
        data.forEach((element) => {
          sum += element[1]
        })
        return sum
      }
      return 0
    }

    return {
      sumSummary,
      useFilter,
      useviewHandlerAmounts,
    }
  },
  components: {
    cardChart,
  },
}
</script>
