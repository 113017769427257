<template>
  <!-- <div class="p-6 h-1/2">
    <base-page-title :title="meta.title" :icon="meta.titleIcon"></base-page-title>
    <flowEditor class="w-full" />
  </div> -->

  <div class="p-6">
    <base-page-title :title="meta.title" :icon="meta.titleIcon"></base-page-title>

    <baseTable
      :fields="useViewConfigurations.fields.value"
      :records="useViewConfigurations.records.value"
      :records-per-page="25"
      :add-button="useContentGuard.canSee('object:write')"
      :edit-button="false"
      :delete-button="useContentGuard.canSee('object:write')"
      :clickAble="true"
      :clickCallback="tableAction"
      :loading="useViewConfigurations.loadHandler.somethingLoading.value"
      :hover-effect="true"
      leadIcon="SlidersIcon"
      :sortable="true"
      @add="useViewConfigurations.openAdd()"
      @delete="useViewConfigurations.deleteConfiguration"
    />

    <!-- add modal -->
    <base-modal
      v-if="useViewConfigurations.showAdd.value"
      @close="useViewConfigurations.closeAdd()"
      :title="`${$translate('Add')}`"
    >
      <baseForm :handler="useViewConfigurations.addForm" />
      <template v-slot:buttons>
        <baseButton
          @action="useViewConfigurations.add()"
          class="mr-2"
          :loading="useViewConfigurations.loadHandler.states.add_configuration"
          >{{ $translate('Save') }}</baseButton
        >
      </template>
    </base-modal>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import router from '@/router'
import contentGuardHandler from '@/use/contentGuardHandler'
// import flowEditor from '@/views/configurations/flowEditor.vue'
import viewHandlerConfigurations from '@/use/viewHandlerConfigurations'

export default {
  setup() {
    const route = useRoute()
    const useContentGuard = contentGuardHandler()
    const useViewConfigurations = viewHandlerConfigurations()

    const meta = computed(() => route.meta)

    function tableAction(payload) {
      router.push({
        name: 'configuration_details',
        params: { objectId: payload.object_id },
      })
    }

    onMounted(() => {
      useViewConfigurations.listRecords()
    })

    return {
      meta,
      useContentGuard,
      useViewConfigurations,
      tableAction,
    }
  },
  components: {
    // flowEditor,
  },
}
</script>
