<template>
  <div class="flex flex-col h-full">
    <!-- buttons -->
    <div>
      <ul class="flex flex-wrap -mb-px justify-between">
        <span class="flex flex-wrap">
          <li
            class="mr-2 my-2 text-sm text-center text-slate-500 shadow-md hover:bg-white"
            v-for="(tab, index) in handler.tabs.value"
            :key="index"
          >
            <a
              @click="handler.onSelect(index)"
              href="#"
              :class="handler.selected.value === index ? 'bg-white border-b-2' : ''"
              class="inline-block p-3 border-b-2 border-transparent"
              >{{ tab }}</a
            >
          </li>
        </span>
        <slot name="button"></slot>
      </ul>
    </div>

    <div class="h-full min-h-0">
      <template v-for="(tab, index) in handler.tabs.value">
        <div class="h-full" :key="index" v-if="index === handler.selected.value">
          <slot :name="`panel-${index}`" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
