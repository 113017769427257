<template>
  <div>
    <base-collapse v-for="(summary, contractid) in data.contracts" :key="contractid" class="mt-2 text-sm">
      <template v-slot:header>
        <div class="flex flex-row">
          <span><featherIcon class="w-5 h-5 mr-1" icon="FileTextIcon" /></span>
          <span class="mr-2">{{ contractTitle(summary.contractinfo) }}</span>
          <span>
            <base-pill :variant="summary.contractinfo.type.toLowerCase()">{{
              $translate(summary.contractinfo.type)
            }}</base-pill>
          </span>
        </div>
      </template>
      <template v-slot:header-right>
        <div class="mr-2">{{ useFilter.toCurrencyWhole(summary.cost_summary.contract_total) }}</div>
      </template>

      <template v-slot:body>
        <!-- Header electricity-->
        <div class="mt-4" v-if="summary.contractinfo.type === 'Electricity'">
          <usageHeaderElectricity />
        </div>

        <!-- Header gas-->
        <div class="mt-4" v-if="summary.contractinfo.type === 'Gas'">
          <usageHeaderGas />
        </div>

        <!-- Header water-->
        <div class="mt-4" v-if="summary.contractinfo.type === 'Water'">
          <usageHeaderWater />
        </div>

        <!-- Header heat-->
        <div class="mt-4" v-if="summary.contractinfo.type === 'Heat'">
          <usageHeaderHeat />
        </div>

        <!-- data -->

        <!-- row -->
        <div class="grid grid-cols-8 text-xs" v-for="record in summary.cost_summary.records" :key="record.node_id">
          <div class="col-span-1 break-all">
            <span v-if="record.description">{{ record.description }}</span>
            <span v-else>{{ record.provider_identifier }}</span>
          </div>
          <div class="col-span-3 text-center">
            <div class="grid grid-cols-3">
              <!-- saldo -->
              <div class="flex flex-row-reverse text-right">
                <span v-if="summary.contractinfo.type === 'Electricity'" class="flex flex-row-reverse">
                  <saldo-tooltip class="ml-2" :data="record" />
                  <span> {{ useFilter.round(record.usage_production_balance_peak) }} {{ record.unit }}</span>
                </span>
              </div>

              <!-- tarief -->
              <div class="text-center">
                <span v-if="summary.contractinfo.type === 'Electricity' && record.usage_production_balance_peak >= 0">{{
                  useFilter.toCurrencyRound(summary.contractinfo.electricity_peak_rate)
                }}</span>
                <span v-if="summary.contractinfo.type === 'Electricity' && record.usage_production_balance_peak < 0">{{
                  useFilter.toCurrencyRound(summary.contractinfo.electricity_peak_return_rate)
                }}</span>
              </div>

              <!-- totaal -->
              <div class="text-left">
                <span v-if="summary.contractinfo.type === 'Electricity'">{{
                  useFilter.toCurrencyWhole(record.costs_peak)
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-span-3 text-center">
            <div class="grid grid-cols-3">
              <!-- saldo -->
              <div class="text-right">
                <span v-if="summary.contractinfo.type === 'Electricity'" class="flex flex-row-reverse">
                  <saldo-tooltip class="ml-2" :data="record" />
                  <span>{{ useFilter.round(record.usage_production_balance_off_peak) }} {{ record.unit }}</span>
                </span>
                <span v-if="summary.contractinfo.type === 'Gas'"
                  >{{ useFilter.round(record.usage) }} {{ record.unit }}</span
                >
                <span v-if="summary.contractinfo.type === 'Water'"
                  >{{ useFilter.round(record.usage) }} {{ record.unit }}</span
                >
                <span v-if="summary.contractinfo.type === 'Heat'"
                  >{{ useFilter.round(record.usage) }} {{ record.unit }}</span
                >
              </div>

              <!-- tarief -->
              <div class="text-center">
                <span
                  v-if="summary.contractinfo.type === 'Electricity' && record.usage_production_balance_off_peak >= 0"
                  >{{ useFilter.toCurrencyRound(summary.contractinfo.electricity_offpeak_rate) }}</span
                >
                <span
                  v-if="summary.contractinfo.type === 'Electricity' && record.usage_production_balance_off_peak < 0"
                  >{{ useFilter.toCurrencyRound(summary.contractinfo.electricity_offpeak_return_rate) }}</span
                >

                <span v-if="summary.contractinfo.type === 'Gas'">{{
                  useFilter.toCurrencyNoRound(summary.contractinfo.gas_rate)
                }}</span>
                <span v-if="summary.contractinfo.type === 'Water'">{{
                  useFilter.toCurrencyNoRound(summary.contractinfo.water_rate)
                }}</span>
                <span v-if="summary.contractinfo.type === 'Heat'">{{
                  useFilter.toCurrencyNoRound(summary.contractinfo.heat_rate)
                }}</span>
              </div>

              <!-- totaal -->
              <div class="text-left">
                <span v-if="summary.contractinfo.type === 'Electricity'">{{
                  useFilter.toCurrencyWhole(record.costs_off_peak)
                }}</span>
                <span v-if="summary.contractinfo.type === 'Gas'">{{
                  useFilter.toCurrencyWhole(record.costs_total)
                }}</span>
                <span v-if="summary.contractinfo.type === 'Water'">{{
                  useFilter.toCurrencyWhole(record.costs_total)
                }}</span>
                <span v-if="summary.contractinfo.type === 'Heat'">{{
                  useFilter.toCurrencyWhole(record.costs_total)
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-span-1 text-center">
            {{ useFilter.toCurrencyWhole(record.costs_total) }}
          </div>
        </div>

        <!-- footer -->
        <div class="grid grid-cols-8 font-bold text-xs mt-2">
          <div class="col-span-1"></div>
          <div class="col-span-3 text-center">
            <div class="grid grid-cols-3">
              <div class="text-right"></div>
              <div class="text-center"></div>
              <div class="text-left"></div>
            </div>
          </div>
          <div class="col-span-3 text-center">
            <div class="grid grid-cols-3">
              <div class="text-right"></div>
              <div class="text-center"></div>
              <div class="text-left">{{ $translate('Subtotal') }}</div>
            </div>
          </div>
          <div class="col-span-1 text-center">{{ useFilter.toCurrencyWhole(summary.cost_summary.contract_total) }}</div>
        </div>
      </template>
    </base-collapse>
  </div>
</template>

<script>
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import filterHandler from '@/use/filterHandler'
import usageHeaderElectricity from './usageHeaderElectricity.vue'
import usageHeaderGas from './usageHeaderGas.vue'
import usageHeaderWater from './usageHeaderWater.vue'
import usageHeaderHeat from './usageHeaderWater.vue'
import saldoTooltip from '@/views/costs/buy/saldoTooltip.vue'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const useFilter = filterHandler()

    function contractTitle(contractinfo) {
      const startDate = useFilter.dateFromEpochDay(contractinfo.start_date)
      const endtDate = useFilter.dateFromEpochDay(contractinfo.end_date)
      return `${contractinfo.provider} (${contractinfo.code}), ${startDate} / ${endtDate}`
    }

    return {
      contractTitle,
      useFilter,
    }
  },
  components: {
    featherIcon,
    usageHeaderElectricity,
    usageHeaderGas,
    usageHeaderWater,
    usageHeaderHeat,
    saldoTooltip,
  },
}
</script>
