<template>
  <div class="shadow-md shadow-gray-100 border border-gray-100 transition-all duration-500 min-h-16 rounded">
    <div class="flex flex-row items-center justify-between cursor-pointer p-3" @click="toggle()">
      <!-- header -->
      <span class="w-full flex flex-row items-center justify-between">
        <slot name="header"></slot>
        <slot v-if="!open" name="header-right"></slot>
      </span>
      <span>
        <featherIcon
          :class="`w-4 h-4 my-auto mr-2 ${rotatedStyle} transition ease-in-out duration-300`"
          icon="ChevronDownIcon"
        />
      </span>
    </div>

    <div v-if="open" class="p-3">
      <!-- body -->
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import featherIcon from '@/components/extended/feather/featherIcon.vue'

export default {
  setup() {
    const open = ref(false)

    function toggle() {
      open.value = !open.value
    }

    const rotatedStyle = computed(() => {
      return open.value ? 'rotate-180' : ''
    })

    return {
      open,
      toggle,
      rotatedStyle,
    }
  },
  components: {
    featherIcon,
  },
}
</script>
