<template>
  <baseNode :class="selected ? 'border border-2 border-red' : ''" :title="title" :handles="handles" :colour="colour" />
</template>

<script>
import baseNode from './baseNode.vue'
import themeHandler from '@/use/themeHandler'

export default {
  props: {
    title: {
      type: String,
      default: 'Voorbeeld',
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const useTheme = themeHandler()
    const colour = useTheme.getBackgroundColor('heatcost')
    const handles = [{ label: 'HeatCost', id: 1, type: 'output' }]

    return {
      handles,
      colour,
    }
  },
  components: {
    baseNode,
  },
}
</script>
