import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import auth from './auth'
import i18n from './plugins/i18n/i18n'
import filters from './plugins/filters/filters'
import base from '@/plugins/base/index'

import * as Sentry from '@sentry/vue'

import './assets/tailwind.css'
const app = createApp(App)
const sentryEnvironment = process.env.VUE_APP_SENTRY_ENVIRONMENT
const sentryDSN = process.env.VUE_APP_SENTRY_DSN

// sentry
Sentry.init({
  app,
  dsn: sentryDSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'app.simpleweg.nl', /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
  environment: sentryEnvironment,
})

app.use(i18n, {})
app.use(filters)
app.use(base)

app.use(store).use(router).use(auth).mount('#app')
