/* eslint-disable no-unused-vars */
export default () => {
  function validate(value, type, rules, payload) {
    // filter type
    const typeFilter = {
      stringStrict: isStringStrict(value),
      string: isString(value),
      number: isNumber(value),
      percentage: isPercentage(value),
      select: true,
      email: isEmail(value),
      password: isPassword(value),
      check: handleChecked(value),
    }

    if (!typeFilter[type]) {
      return false
    }

    return true
    // const ruleFilter = {
    //   min: isMin(value),
    //   max: isMax(value),
    // }
  }

  function isStringStrict(value) {
    const pattern = /[^a-zA-Z ]/
    return !pattern.test(value)
  }

  function isString(value) {
    const pattern = /^[a-zA-Z0-9\-()\s]*$/
    return pattern.test(value)
  }

  function isNumber(value) {
    const pattern = /^(?:(?:0|[1-9][0-9]*)(?:\.[0-9]*)?|\.[0-9]*)$|^$/
    return pattern.test(value)
  }

  function isPercentage(value) {
    const pattern = /^([0-9]|[1-9][0-9]|100)$|^$/
    return pattern.test(value)
  }

  function isEmail(value) {
    const pattern = /^[a-zA-Z0-9_.-@]*$/
    return pattern.test(value)
  }

  function isPassword(value) {
    const pattern = /^\S*$/
    return pattern.test(value)
  }

  function handleChecked(value) {
    return value
  }

  return {
    validate,
  }
}
