<template>
  <!-- string -->
  <input
    :disabled="handler.loading.value || disabled || field.disabled"
    v-if="field.type === 'string'"
    class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 text-sm disabled:bg-gray-100"
    :id="field.key"
    type="text"
    :value="field.translate_value ? useTranslate.t(handler.data.value[field.key]) : handler.data.value[field.key]"
    @input="handler.validate($event, 'string', [], field.key)"
  />

  <!-- password -->
  <input
    :disabled="handler.loading.value || disabled || field.disabled"
    v-if="field.type === 'password'"
    class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 text-sm disabled:bg-gray-100"
    :id="field.key"
    type="password"
    :value="handler.data.value[field.key]"
    @input="handler.validate($event, 'password', [], field.key)"
  />

  <!-- email -->
  <input
    :disabled="handler.loading.value || disabled || field.disabled"
    v-if="field.type === 'email'"
    class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 text-sm disabled:bg-gray-100"
    :id="field.key"
    type="text"
    :value="handler.data.value[field.key]"
    @input="handler.validate($event, 'email', [], field.key)"
  />

  <!-- number -->
  <input
    v-if="field.type === 'number'"
    :disabled="handler.loading.value || disabled"
    class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 text-sm disabled:bg-gray-100"
    :id="field.key"
    :value="handler.data.value[field.key]"
    type="text"
    @input="handler.validate($event, 'number', [], field.key)"
  />

  <!-- percentage -->
  <input
    v-if="field.type === 'percentage'"
    :disabled="handler.loading.value || disabled"
    class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 text-sm disabled:bg-gray-100"
    :id="field.key"
    :value="handler.data.value[field.key]"
    type="text"
    @input="handler.validate($event, 'percentage', [], field.key)"
  />

  <!-- selects -->
  <div class="relative" v-if="field.type === 'select'" tabindex="0">
    <!-- if disabled -->
    <div v-if="handler.loading.value || disabled || field.disabled">
      <div
        class="flex justify-between bg-gray-100 border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm"
      >
        <div>{{ handler.data.value[field.key] }}</div>
        <div>
          <!-- <featherIcon class="w-4 h-4 my-auto mr-2" icon="ChevronDownIcon" /> -->
        </div>
      </div>
    </div>
    <!-- if not disabled -->
    <div
      v-else
      ref="selectRef"
      :class="requiredAndFilled(field) ? 'bg-white' : 'bg-red-100 border-red-200'"
      class="flex justify-between bg-white border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm disabled:bg-gray-100 disabled:border-gray-200 cursor-pointer"
      @click="toggleSelect()"
    >
      <div>
        {{
          field.translate_value
            ? useTranslate.t(getLabel(handler.data.value[field.key], field.options))
            : getLabel(handler.data.value[field.key], field.options)
        }}
      </div>
      <div>
        <featherIcon class="w-4 h-4 my-auto mr-2" icon="ChevronDownIcon" />
      </div>
    </div>

    <ul
      class="absolute bg-white w-full border border-gray-200 p-2 text-gray-700 text-sm mt-1 shadow-lg z-50 overflow-auto"
      v-if="selectOpen"
    >
      <li
        class="cursor-pointer hover:bg-gray-50"
        v-for="(option, index) in field.options"
        :key="index"
        @click="handler.validate({ target: { value: option.value } }, 'select', [], field.key)"
      >
        {{ field.translate_value ? useTranslate.t(option.label) : option.label }}
      </li>
    </ul>
  </div>

  <!-- subtitle -->
  <span class="text-gray-500 text-sm" v-if="field.type === 'subtitle'">{{ field.label }} </span>

  <!-- list -->
  <baseListInput
    v-if="field.type === 'list'"
    :disabled="handler.loading.value || disabled"
    :values="handler.data.value[field.key]"
    :handler="handler"
    :keyField="field.key"
    :fieldConfig="field.fieldConfig"
    :displayTemplate="field.displayTemplate"
    :modalSize="field.modalSize"
    @add="(...args) => handler.addListItem(field.key, ...args)"
    @remove="(...args) => handler.removeListItem(field.key, ...args)"
    @edit="(...args) => handler.editListItem(field.key, ...args)"
  />

  <!-- toggle -->
  <span v-if="field.type === 'toggle'">
    <label class="relative inline-flex items-center cursor-pointer">
      <input
        :value="true"
        :disabled="handler.loading.value || disabled"
        type="checkbox"
        class="sr-only peer"
        :checked="handler.data.value[field.key] === true ? true : false"
        @input="handler.validate($event, 'check', [], field.key)"
      />
      <div
        class="my-auto w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-sky-700"
      ></div>
    </label>
  </span>

  <!-- date -->
  <baseDateInput
    v-if="field.type === 'date'"
    :disabled="handler.loading.value || disabled"
    :value="handler.data.value[field.key]"
    :handler="handler"
    :keyField="field.key"
    :fieldConfig="field.fieldConfig"
    :displayTemplate="field.displayTemplate"
    :modalSize="field.modalSize"
    @add="(...args) => handler.setValue(field.key, ...args)"
    @remove="(...args) => handler.setValue(field.key, ...args)"
    @edit="(...args) => handler.setValue(field.key, ...args)"
  />

  <!-- area -->
  <span v-if="field.type === 'area'" class="flex">
    <input
      :disabled="handler.loading.value || disabled"
      class="bg-white appearance-none border border-gray-200 rounded-l w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 text-sm"
      :id="field.key"
      type="text"
      :value="handler.data.value[field.key]"
      @input="handler.validate($event, 'number', [], field.key)"
    />

    <baseButton class="rounded-r ml-1" @action="calculatorHandler.open(handler)" size="small"
      ><CalculatorIcon class="h-5 w-5"
    /></baseButton>
  </span>

  <!-- labels -->
  <span v-if="field.type === 'tags'" :disabled="handler.loading.value || disabled || field.disabled">
    <fieldLabel
      :mode="field.mode"
      :tags="handler.data.value[field.key]"
      @remove="(...args) => handler.removeListItem(field.key, ...args)"
      @add="(...args) => handler.addListItem(field.key, ...args)"
    />
  </span>

  <!-- checkboxes -->
  <span v-if="field.type === 'checkboxes'">
    <fieldCheckboxes
      :data="handler.data.value[field.key]"
      :field="field"
      @update="(...args) => handler.setValue(field.key, ...args)"
    />
  </span>
</template>

<script>
import { CalculatorIcon } from '@heroicons/vue/24/outline'
import { ref, onMounted, onUnmounted } from 'vue'
import useCalculator from '@/use/calculatorHandler'
import fieldLabel from '../components/form/fieldLabel'
import fieldCheckboxes from '../components/form/fieldCheckboxes.vue'
import featherIcon from '@/components/extended/feather/featherIcon.vue'
import translationHandler from '@/plugins/i18n/translationHandler'

export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const calculatorHandler = useCalculator()
    const selectOpen = ref(false)
    const selectRef = ref(null)
    const useTranslate = translationHandler()

    function requiredAndFilled(field) {
      if (field.required) {
        const fieldValue = props.handler.data.value[field.key]
        if (fieldValue) {
          return true
        }
        return false
      }
      return true
    }

    function toggleSelect() {
      selectOpen.value = !selectOpen.value
    }

    function clicker(event) {
      if (selectRef.value) {
        if (!selectRef.value.contains(event.target)) {
          if (selectOpen.value) {
            toggleSelect()
          }
        }
      }
    }

    function getLabel(value, optionsArray) {
      const foundOption = optionsArray.find((option) => option.value === value)
      return foundOption ? foundOption.label : ''
    }

    onMounted(() => {
      window.addEventListener('click', clicker)
    })

    onUnmounted(() => window.removeEventListener('click', clicker))

    return {
      calculatorHandler,
      selectOpen,
      selectRef,
      toggleSelect,
      requiredAndFilled,
      getLabel,
      useTranslate,
    }
  },

  components: {
    CalculatorIcon,
    fieldLabel,
    fieldCheckboxes,
    featherIcon,
  },
}
</script>
