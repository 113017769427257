<template>
  <div class="max-w-96 w-44 bg-white border border-black rounded text-sm">
    <div class="flex flex-col">
      <!-- title -->
      <div class="flex flex-row p-2 justify-between" :class="colour">
        <div>{{ title ? title : 'Unknown' }}</div>

        <!-- <base-button-drop-down
          class="!p-0"
          :handler="nodeDropDown"
          :display-text="''"
          variant="transparent"
          size="small"
          icon="MoreVerticalIcon"
        >
          <base-button-drop-down-item @click="onDelete()">
            <div class="flex flex-row">
              <featherIcon class="w-3 h-3 mr-1 my-auto" icon="TrashIcon" />
              <div class="my-auto">Verwijderen</div>
            </div>
          </base-button-drop-down-item>
        </base-button-drop-down> -->
      </div>

      <!-- content -->
      <div class="divide-y divide-black">
        <div v-for="(handle, index) in handles" :key="'handle-' + index" class="flex flex-row w-full">
          <!-- left handle -->
          <Handle
            v-if="handle.type === 'input'"
            type="target"
            position="left"
            class="relative my-auto"
            :id="'handle-' + index"
          />

          <!-- label -->
          <div class="p-1 w-full">{{ handle.label }}</div>

          <!-- right handle -->
          <Handle
            v-if="handle.type === 'output'"
            type="target"
            position="right"
            class="relative my-auto"
            :id="'handle-' + index"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Handle } from '@vue-flow/core'
import baseButtonDropDownHandler from '@/use/baseButtonDropDownHandler'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    handles: {
      type: Array,
      default: () => [],
    },
    colour: {
      type: String,
      default: 'bg-gray-300',
    },
  },
  setup(_, { emit }) {
    const nodeDropDown = baseButtonDropDownHandler()

    function onDelete() {
      emit('delete')
    }

    return {
      nodeDropDown,
      onDelete,
    }
  },
  components: {
    Handle,
  },
}
</script>
