<template>
  <div class="p-6 h-screen">
    <base-page-title :title="pageTitle" :suffix="pageSuffix" :icon="meta.titleIcon"></base-page-title>

    <!-- buttons -->
    <div class="flex flex-row justify-between mb-2">
      <!-- left -->
      <div class="flex flex-row">
        <base-button size="small" class="mr-2" @action="onOpenAddNodes()">Nodes</base-button>

        <base-button-drop-down :handler="functionDropDown" :display-text="'Functions'" size="small" variant="neutral">
          <base-button-drop-down-item>
            <div class="my-auto" @click="addFunctionNode('summary')">Summary</div>
          </base-button-drop-down-item>
          <base-button-drop-down-item>
            <div class="my-auto" @click="addFunctionNode('report')">Report</div>
          </base-button-drop-down-item>
          <base-button-drop-down-item>
            <div class="my-auto" @click="addFunctionNode('time')">Time filter</div>
          </base-button-drop-down-item>
        </base-button-drop-down>
      </div>

      <!-- right -->
      <div>
        <base-button size="small" @action="useFlow.fit()" class="mr-2 h-full">
          <featherIcon class="w-4 h-4" icon="HomeIcon" />
        </base-button>

        <base-button size="small" @action="onExecuteGraph()" class="mr-2 h-full">
          <featherIcon class="w-4 h-4" icon="PlayIcon" />
        </base-button>

        <base-button
          class="h-full"
          size="small"
          @action="onSave()"
          :loading="useConfigurationDetails.loadHandler.states.update_configuration"
        >
          <featherIcon class="w-4 h-4" icon="SaveIcon" />
        </base-button>
      </div>
    </div>

    <!-- floweditor -->
    <flowEditor class="w-full h-2/5" @ready="onReady" />

    <!-- add nodes modal -->
    <base-modal
      v-if="useConfigurationDetails.showModalAddNodes.value"
      @close="useConfigurationDetails.closeModalAddNodes()"
      :title="`${$translate('Add')}`"
      size="l"
    >
      <baseTable
        :fields="useConfigurationDetails.nodeFields.value"
        :records="useConfigurationDetails.availableNodes.value"
        :records-per-page="15"
        :add-button="false"
        :edit-button="false"
        :delete-button="false"
        :selectable="true"
        :loading="useConfigurationDetails.loadHandler.states.list_nodes"
        :hover-effect="true"
        leadIcon="CircleIcon"
        :search="true"
        @selection="useConfigurationDetails.setSelectedAddNodes"
        :sortable="true"
      />

      <template v-slot:buttons>
        <baseButton @action="onAddNodes()" class="mr-2" :loading="useFlow.loadHandler.states.add_nodes">{{
          $translate('Add')
        }}</baseButton>
      </template>
    </base-modal>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, onMounted, ref } from 'vue'
import contentGuardHandler from '@/use/contentGuardHandler'
import flowEditor from '@/views/configurations/flowEditor.vue'

import viewHandlerConfigurationDetails from '@/use/viewHandlerConfigurationDetails'
import flowHandler from '@/use/flowHandler'
import baseButtonDropDownHandler from '@/use/baseButtonDropDownHandler'

export default {
  setup() {
    const route = useRoute()
    const useContentGuard = contentGuardHandler()
    const meta = computed(() => route.meta)
    const useFlow = flowHandler()
    const useConfigurationDetails = viewHandlerConfigurationDetails()
    const instanceStore = ref(null)
    const functionDropDown = baseButtonDropDownHandler()

    const pageTitle = computed(() => {
      return meta.value.title
    })

    const pageSuffix = computed(() => {
      if (useConfigurationDetails.configuration.value) {
        useConfigurationDetails.configuration.value.description
      }
      return ''
    })

    function onSave() {
      const payload = {
        configuration: {
          elements: useFlow.elements.value,
          edges: useFlow.edges.value,
        },
      }
      console.log(instanceStore.value)
      useConfigurationDetails.updateConfiguration(payload, route.params.objectId)
    }

    function onOpenAddNodes() {
      useConfigurationDetails.openModalAddNodes()
      useConfigurationDetails.listNodes()
    }

    function onAddNodes() {
      const selectedAddNodes = useConfigurationDetails.selectedAddNodes.value
      useFlow.addNodes(selectedAddNodes).then(() => {
        console.log(instanceStore.value.elements)
        useFlow.customFitView(instanceStore.value)
      })
      useConfigurationDetails.closeModalAddNodes()
    }

    function onReady(instance) {
      instanceStore.value = instance
      useConfigurationDetails
        .getDetails({
          object_id: route.params.objectId,
        })
        .then((result) => {
          if (result.configuration.elements) {
            useFlow.loadElements(result.configuration)
            useFlow.customFitView(instance)
          }
        })
    }

    function addFunctionNode(type) {
      const descriptioNSelector = {
        summary: 'Sum',
        report: 'Report',
        time: 'Time filter',
      }

      const payload = {
        type: type,
        description: descriptioNSelector[type],
      }

      useFlow.addNode(payload)
    }

    async function onExecuteGraph() {
      try {
        await useFlow.executeGraph(instanceStore.value)
        console.log('Graph executed successfully')
      } catch (error) {
        console.error('Error executing graph:', error)
      }
    }

    onMounted(async () => {
      // console.log('mounted 2')
      // const result = await useConfigurationDetails.getDetails({
      //   object_id: route.params.objectId,
      // })
      // await useFlow.loadElements(result.configuration.elements)
      // useFlow.fit()
    })

    return {
      meta,
      useContentGuard,
      pageTitle,
      pageSuffix,
      useFlow,
      onSave,
      onOpenAddNodes,
      useConfigurationDetails,
      onAddNodes,
      onReady,
      functionDropDown,
      addFunctionNode,
      onExecuteGraph,
      instanceStore,
    }
  },
  components: {
    flowEditor,
  },
}
</script>
