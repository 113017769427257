import apiHandler from './apiHandler'

export default () => {
  const useApi = apiHandler()

  function getConnectedNodes(nodeIdToFind, nodes, edges) {
    const nodeId = nodeIdToFind
    const connectedEdgeIds = edges.value.filter((edge) => edge.source === nodeId || edge.target === nodeId)
    const connectedNodeIds = connectedEdgeIds.map((edge) => (edge.source === nodeId ? edge.target : edge.source))
    return nodes.value.filter((node) => connectedNodeIds.includes(node.id))
  }

  async function executeSummary(node, elements, edges) {
    return new Promise((resolve, reject) => {
      try {
        const result = getConnectedNodes(node.id, elements, edges)
        const resultIds = result.map((node) => node.id)

        const payload = {
          ids: resultIds,
          interval: 'day',
          start_date: 1704067200000,
          end_date: 1735689600000,
        }

        const queryString = useApi.toQueryString(payload)

        useApi.request_api('get', 'v1', 'nodes/data/summary?' + queryString).then((response) => {
          const data = response.data
          resolve(data)
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  async function reportNodeFunction(node, elements, edges, inputData) {
    // Assuming inputData contains the results of the dependent nodes
    const reportData = inputData
    console.log('Report Data %%%%%%%:', reportData)
    // Generate a report based on inputData
    return 'Report generated'
  }

  const nodeFunctions = {
    summary: executeSummary,
    report: reportNodeFunction,
  }

  return {
    nodeFunctions,
  }
}
